<template>
  <div>
    <get-funded />
  </div>
</template>

<script>
import GetFunded from '@/views/shared/GetFunded'

export default {
  components: {
    GetFunded
  },
};
</script>
